.paginationwrapper {
  text-align: center;
}

.pagination {
  border-radius: 0;
  margin-bottom: 0;
}

.pagination > li {
  span, a {
    color: $color-darkgrey;
    background: $color-grey url(/assets/img/layout/pagination-sprite.png) repeat-x;
    border-width: 0 1px;
    border-radius: 0 !important;
    margin-bottom: 15px;

    @media #{$xs-only} {
      padding: 8px 14px;
    }
  }
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  border-width: 0 1px;
  background-position: 0 bottom;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  border-color: $color-darkgrey;
  opacity: .6;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-left: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-right: 0;
}
