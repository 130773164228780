.itemslist {
  .item {
    .item__title {
      margin: 15px 0;
      font-size: 22px;
      line-height: 24px;
    }

    .item__intro {
      //
    }

    .item__date {
      color: $color-grey;
    }

    .item__imagewrapper {
      display: block;
      margin-top: 20px;

      @media #{$sm-up} {
        margin-top: 0;
      }
    }

    & + .item:before {
      margin: 30px 0;
      height: 1px;
      content: '';
      display: block;
      background: #dcdcdc;
      border-top: 1px solid #727272;
    }
  }
}
