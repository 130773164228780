.header {
  color: #fff;
  padding: 30px 0;
  margin-bottom: 30px;
  background-color: $color-darkgrey;
  @include background-image-retina('/assets/img/layout/pattern-striped', 'png', 12px, 12px);

  &.header--has-quickmenu {
    margin-bottom: 50px;

    .header__bannerbox {
      margin-bottom: 20px;
    }

    @media #{$md-up} {
      margin-bottom: 130px;
      padding-bottom: 65px;
    }
  }

  .container {
    position: relative;
  }

  .header__logo {
    a {
      width: 230px;
      height: 64px;
      margin: 0 auto 30px auto;
      display: block;
      text-indent: -9999px;
      @include background-image-retina('/assets/img/logos/schinkel-installatietechniek', 'png', 230px, 64px);

      @media #{$sm-up} {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .header__nav {
    margin-bottom: 20px;

    li {
      display: inline-block;
      line-height: 22px;
      font-family: $fontstack-semibold;
      vertical-align: top;
      text-transform: uppercase;

      a {
        color: #fff;
        padding: 6px 5px;
        display: block;
      }

      &:hover, &.current {
        a {
          color: $color-yellow;
        }
      }

      &:first-child a {
        width: 29px;
        font-size: 0;
        position: relative;

        &:before {
          top: 6px;
          bottom: 0;
          position: absolute;
          content: '';
          display: block;
          width: 19px;
          height: 19px;
          @include sprite(0, -69px);
        }

        &:hover {
          &:before {
            background-position: -19px -69px;
          }
        }
      }
    }

    @media #{$md-up} {
      top: 50px;
      right: 20px;
      position: absolute;
    }
  }

  .header__top {
    i {
      display: none;
      font-style: normal;
    }

    @media #{$sm-up} {
      top: 0;
      right: 15px;
      position: absolute;

      i {
        display: inline;
      }
    }
  }

  .header__bannerbox {
    margin: 20px auto 0 auto;
    overflow: hidden;
    max-height: 220px;

    &.owl-carousel {
      max-height: 321px;
    }
  }

  .header__quickmenu {
    .list__item {
      display: block;
      position: relative;
      font-size: 14px;
      text-align: center;
      background: url(/assets/img/layout/gradient-grey.png) repeat-x;
      border-top: 1px solid #cecece;
      font-family: $fontstack-semibold;
      border-bottom: 1px solid #a8a8a8;

      @media (max-width: 499px) {
        &:first-child {
          border-top: 0 !important;
        }

        &:last-child {
          border-bottom: 0 !important;
        }
      }

      @media (min-width: 500px) {
        width: 50%;
        float: left;
        border-left: 1px solid #a8a8a8;
      }

      &.list__item--yellow {
        background-image: url(/assets/img/layout/gradient-yellow.png);

        a {
          color: #4f4f4f;
        }
      }

      &:hover, &.list__item--current {
        background-image: url(/assets/img/layout/gradient-darkgrey.png);

        a {
          color: #4f4f4f;
        }
      }

      a {
        color: #676767;
        display: block;
        padding: 5px 10px 5px 50px;
        position: relative;
        line-height: 40px;
      }

      .icon {
        left: 20px;
        position: absolute;
      }

      @media #{$md-up} {
        a {
          padding: 15px 10px 5px 10px;
          line-height: 22px;
        }

        .icon {
          left: 0;
          margin: 0 auto 10px auto;
          display: block;
          position: relative;
        }

        &:after {
          left: 0;
          width: 100%;
          height: 0;
          bottom: 0;
          content: '';
          display: block;
          position: absolute;
          background: $color-yellow;
          transition: height ease-in-out .2s;
        }

        &:hover:after, &.list__item--current:after {
          height: 4px;
        }
      }
    }

    @media #{$md-up} {
      left: 0;
      right: 0;
      margin: 0 50px;
      bottom: -128px;
      position: absolute;

      .list__item {
        width: 14%;
        border-top: 0;
        border-left: 1px solid #cecece;
        border-right: 1px solid #a8a8a8;
        border-bottom: 0;

        a {
          height: 116px;
        }

        &:first-child {
          border-left: 0 !important;
        }

        &:last-child {
          border-right: 0 !important;
        }
      }

      .list__item + .list__item {
        border-left: 1px solid #cecece;
      }
    }
  }
}
