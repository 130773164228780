h1, h2, h3, h4, h5, h6 {
  font: normal 20px/24px $fontstack-semibold;
  margin: 0;
}

address {
  font-style: normal;
}

blockquote {
  margin: 0;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: darken($color-yellow, 15%);
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: darken($color-yellow, 25%);
    text-decoration: none;
  }
}

strong, b {
  font-family: $fontstack-semibold;
  font-weight: normal;
}
