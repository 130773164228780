.gallery {
  @include clearfix();

  .item {
    float: left;
    width: 47%;
    margin-bottom: 18px;

    &:first-child {
      width: 100%;
      float: none;
    }

    // All images on the right side
    &:nth-child(2n + 1) {
      float: right;
    }

    img {
      margin: 0;
    }
  }
}
