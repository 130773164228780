.tblock {
  margin: 0 0 30px 0;
  position: relative;

  @media #{$sm-up} {
    &.tblock--border-left:before,
    &.tblock--border-right:before {
      top: 0;
      width: 3px;
      height: 75%;
      content: '';
      display: block;
      position: absolute;
      background: $color-yellow;
    }

    &.tblock--border-left {
      padding-left: 50px;
      &:before {
        left: 20px;
      }
    }

    &.tblock--border-right {
      padding-right: 50px;
      &:before {
        right: 20px;
      }
    }
  }

  .tblock__title {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 26px;

    span {
      font: 18px/20px $fontstack-light;
      display: block;
    }

    @media #{$sm-up} {
      font-size: 30px;
      line-height: 30px;

      span {
        font-size: 22px;
        line-height: 22px;
      }
    }
  }

  .tblock__body {
    margin-bottom: 26px;
  }
}

.footer .tblock {
  @media #{$md-up} {
    margin-left: 30px;
    margin-right: 30px;
  }
}

