.title {
  color: #000;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  font-family: $fontstack-semibold;
  font-weight: normal;
  margin-bottom: 46px;

  &:after {
    width: 100px;
    content: '';
    display: block;
    margin: 12px auto;
    border-top: 3px solid $color-yellow;
  }

  &.title--white {
    color: #fff;
  }

  @media #{$sm-up} {
    font-size: 30px;
    line-height: 32px;
  }
}
