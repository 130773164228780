body {
  color: #000;
  font-size: 16px;
  font-family: $fontstack-regular;
}

button {
  border: none;
}

.container {
  max-width: 1010px;
}

.debug [class^="col-"] {
  background: rgba(0,0,0,.2);
  border-left: 1px solid rgba(0,0,0,.4);
  border-right: 1px solid rgba(0,0,0,.4);
}

.main {
  margin-bottom: 60px;
}

.content {
  @media #{$md-up} {
    margin: 0 35px;
  }
}

// Unveil
img[data-src] {
  opacity: 0;
  transition: opacity .3s ease-in;
}
