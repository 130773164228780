.icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  @include sprite();

  &.icon--a {
    background-position: 0 -29px;
  }
  &.icon--b {
    background-position: -40px -29px;
  }
  &.icon--c {
    background-position: -80px -29px;
  }
  &.icon--d {
    background-position: -120px -29px;
  }
  &.icon--e {
    background-position: -160px -29px;
  }
  &.icon--f {
    background-position: -200px -29px;
  }
  &.icon--g {
    background-position: -240px -29px;
  }
}
