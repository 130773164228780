/* Generated by Font Squirrel (http://www.fontsquirrel.com) on June 22, 2015 */

@font-face {
  font-family: 'hind_light';
  src: url('/assets/fonts/hind_300-webfont.eot');
  src: url('/assets/fonts/hind_300-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/hind_300-webfont.woff2') format('woff2'),
       url('/assets/fonts/hind_300-webfont.woff') format('woff'),
       url('/assets/fonts/hind_300-webfont.ttf') format('truetype'),
       url('/assets/fonts/hind_300-webfont.svg#hindlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hind_semibold';
  src: url('/assets/fonts/hind_600-webfont.eot');
  src: url('/assets/fonts/hind_600-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/hind_600-webfont.woff2') format('woff2'),
       url('/assets/fonts/hind_600-webfont.woff') format('woff'),
       url('/assets/fonts/hind_600-webfont.ttf') format('truetype'),
       url('/assets/fonts/hind_600-webfont.svg#hindsemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hind_bold';
  src: url('/assets/fonts/hind_700-webfont.eot');
  src: url('/assets/fonts/hind_700-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/hind_700-webfont.woff2') format('woff2'),
       url('/assets/fonts/hind_700-webfont.woff') format('woff'),
       url('/assets/fonts/hind_700-webfont.ttf') format('truetype'),
       url('/assets/fonts/hind_700-webfont.svg#hindbold') format('svg');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'hind_regular';
  src: url('/assets/fonts/hind_regular-webfont.eot');
  src: url('/assets/fonts/hind_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/hind_regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/hind_regular-webfont.woff') format('woff'),
       url('/assets/fonts/hind_regular-webfont.ttf') format('truetype'),
       url('/assets/fonts/hind_regular-webfont.svg#hind_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
