/* Helpers */

// Better Font Rendering
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-bold {
  font-family: $fontstack-semibold;
}

.img--border-top {
  border-top: 4px solid $color-yellow;
}

.img--centered {
  margin: 0 auto;
  display: block;
}

.img--style1 {
  margin: 0 auto 30px auto;
  display: block;
  border-top: 4px solid $color-yellow;
}

.img--style2 {
  margin: 0 auto 30px auto;
  display: block;
}
