.footer {
  color: #fff;
  font-size: 14px;
  background: #000 url(/assets/img/layout/footer-background.jpg) no-repeat center 0;

  @media #{$xs-only} {
    background-size: auto 100%;
  }

  a {
    color: #fff;
  }

  .seperator {
    border-top: 1px solid #d3d3d2;
    margin-bottom: 30px;
    border-bottom: 1px solid $color-darkgrey;
  }

  .footer__top {
    @include background-image-retina('/assets/img/layout/pattern-striped', 'png', 12px, 12px);
    padding: 53px 0 30px 0;
  }

  .footer__quote {
    height: 75px;
    margin: 20px auto 30px auto;
    display: block;
    max-width: 361px;
    background: url(/assets/img/layout/footer-quote.png) no-repeat;
    text-indent: -9999px;
    background-size: 100% auto;

    @media #{$md-up} {
      margin-bottom: 0;
    }
  }

  .footer__logo {
    margin: 20px auto 40px;
  }

  .footer__address {
    font-size: 14px;
    line-height: 22px;

    h5 {
      color: $color-yellow;
      font-size: 16px;
      margin-bottom: 2px;
    }

    span {
      width: 90px;
      display: inline-block;

      @media #{$md-up} {
        width: 60px;
      }
    }
  }

  .footer__bottom {
    padding: 25px 10px;
    background: #000;
    text-align: center;
    line-height: 24px;

    .linksbox {
      margin: 15px 0;

      li {
        display: inline-block;
        text-align: center;
        vertical-align: top;
      }

      a {
        margin: 0 5px;
        display: inline-block;
        padding: 3px 5px;
        line-height: 20px;

        &:hover {
          color: $color-yellow;
        }
      }

      li + li {
        border-left: 1px solid #fff;
        a {
          margin-left: 6px;
        }
      }
    }

    .concept {
      a {
        width: 195px;
        height: 29px;
        margin: 10px auto 0 auto;
        display: block;
        font-size: 0;
        background: url(/assets/img/logos/active-creations.png) no-repeat;

        &:hover {
          background-position: 0 bottom;
        }

        @media (min-width: 500px) {
          margin: 0 0 0 15px;
          display: inline-block;
        }
      }
    }

    @media #{$md-up} {
      .concept, .linksbox {
        display: inline;
      }

      .concept {
        float: right;
        margin-left: 20px;

        a {
          top: -8px;
          position: relative;
        }
      }

      .copyright {
        float: left;
        display: inline;
      }
    }
  }
}
