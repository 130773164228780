a.btn, .btn {
  color: #000;
  height: 29px;
  border: 0;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  padding: 0 10px 0 41px;
  position: relative;
  font-size: 14px;
  max-width: 100%;
  line-height: 29px;
  max-height: 29px;
  overflow: hidden;
  background: transparent;
  font-family: $fontstack-semibold;

  &.btn--block {
    width: 100%;
    display: block;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .6;
  }

  span {
    padding: 0 8px;
    display: block;
    background: $color-yellow;

    &:before, &:after {
      top: 0;
      height: 29px;
      content: '';
      display: block;
      @include sprite();
      position: absolute;
    }

    &:before {
      left: 0;
      width: 41px;
      background-position: 0 0;
    }

    &:after {
      right: 0;
      width: 10px;
      background-position: -41px 0;
    }
  }

  &.btn:hover, &.btn--grey {
    span {
      background: $color-lightgrey;

      &:before {
        background-position: -51px 0;
      }
      &:after {
        background-position: -92px 0;
      }
    }
  }

  &.btn--white-on-hover:hover, &.btn--white {
    span {
      background: #fff;

      &:before {
        background-position: -102px 0;
      }
      &:after {
        background-position: -143px 0;
      }
    }
  }

  &.btn--arrow-right {
    padding-left: 7px;
    padding-right: 46px;

    span {
      &:before {
        width: 7px;
        background-position: -208px 0;
      }
      &:after {
        width: 46px;
        background-position: -215px 0;
      }
    }

    &.btn--red-on-hover:hover {
      span {
        color: #fff;
        background: #f00;

        &:before {
          background-position: -153px 0;
        }
        &:after {
          background-position: -162px 0;
        }
      }
    }
  }

  &.btn--menu, &.btn--menu:hover {
    padding-right: 46px;

    span {
      background: $color-yellow;

      &:before {
        background-position: 0 0;
      }

      &:after {
        width: 46px;
        background-position: -261px 0;
      }
    }
  }
}
