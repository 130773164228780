.breadcrumb {
  padding: 0;
  font-size: 15px;
  list-style: none;
  line-height: 22px;
  margin-bottom: 30px;

  > li {
    display: inline-block;

    + li:before {
      color: $color-grey;
      content: "/\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
    }
  }

  a {
    color: $color-grey;

    &:hover {
      text-decoration: underline;
    }
  }

  > .current {
    font-family: $fontstack-semibold;
  }
}
