.footer .newsblock {
  margin-bottom: 30px;

  & + .newsblock {
    @media #{$xs-only} {
      margin-top: 80px;
    }
  }

  .newsblock__title {
    color: $color-yellow;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 14px;

    @media #{$md-up} {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .newsblock__date {
    font-size: 14px;
    line-height: 24px;

    &:after {
      width: 34px;
      margin: 10px 0 0 0;
      content: '';
      display: block;
      border-top: 1px solid #d8d8d8;
      border-bottom: 1px solid #fff;
    }
  }

  .newsblock__text {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
  }

  .newsblock__imagewrapper {
    display: block;
    border-top: 3px solid $color-yellow;
    line-height: 1;

    @media #{$xs-only} {
      margin-top: 30px;
    }
  }
}
