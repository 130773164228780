// Variables
$color-yellow: #fccb37;
$color-darkgrey: #2e2d2c;
$color-grey: #676767;
$color-lightgrey: #cecece;

// Mixins
@mixin coverer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

@mixin centerer {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin word-wrap {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hypens: auto;
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin sprite($left: 0px, $right: 0px) {
  background-image: url(/assets/img/layout/sprite.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: $left $right;
}

@mixin background-image-retina($file, $type, $width, $height, $repeat: 'no-repeat') {
  background-image: url($file + '.' + $type);
  background-repeat: $repeat;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background-image: url($file + '@2x.' + $type);
    background-size: $width $height;
  }
}
