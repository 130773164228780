.stel-uw-vraag {
  width: 267px;
  height: 27px;
  margin: 0 auto 30px auto;
  display: block;
  text-indent: -99999px;
  @include background-image-retina('/assets/img/layout/stel-uw-vraag', 'jpg', 267px, 27px);
}

// Back to top
.backtotop {
  right: 20px;
  width: 39px;
  height: 32px;
  bottom: -40px;
  display: block;
  z-index: 200;
  position: fixed;
  transition: bottom 0.4s;

  @include sprite(-38px, -69px);

  .js-show-back-to-top & {
    bottom: 20px;
  }
}

// Trigger mobile menu
#trigger-overlay {
  margin-bottom: 20px;
}
