.control-label {
  font-size: 14px;
  font-family: $fontstack-semibold;
  font-weight: normal;
}
.form-horizontal .control-label {
  text-align: left;
}
.form-control {
  font-size: 14px;
}
.form-control:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 4px rgba(46, 45, 44, 0.3);
}
.has-error .form-control {
  background: pink;
}

.contactformbox {
  @media #{$sm-up} {
    margin: 0 80px;
  }
  @media #{$md-up} {
    margin: 0 140px;
  }

  textarea {
    height: 150px;
    resize: none;
  }
}

.form-control:disabled {
  color: #999;
  cursor: not-allowed;
  background-color: #f6f6f6;
}

.spinner {
  display: inline-block;
  font-size: 14px;
  background: url(/assets/img/icons/spinner2.gif) no-repeat 0 50%;
  visibility: hidden;
  line-height: 20px;
  padding-left: 30px;

  .js-loading & {
    visibility: visible;
  }
}

.alert {
  font-size: 14px;
}

.form--404 {
  .form-control {
    @media #{$xs-only} {
      margin-bottom: 20px;
    }
  }

  margin-bottom: 40px;
}
