.contactfooter {
  margin: 0 0 30px 0;

  .footer__address span {
    width: 90px;
  }

  @media #{$xs-only} {
    .footer__address {
      margin-bottom: 30px;
    }
  }

  .footer__logo {
    margin-top: 0;
  }

  @media #{$sm-up} {
    .footer__logo {
      margin: 0 0 30px 0;
    }
  }

  @media #{$md-up} {
    margin-left: 98px;
    margin-right: 98px;
  }
}
