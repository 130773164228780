/* Textbox */
.textbox {
  font: 14px/20px Verdana, Arial, sans-serif;

  p, ul, ol {
    margin-bottom: 22px;
  }

  h1, h2, h3 {
    font: 22px/26px Verdana, Arial, sans-serif;
    margin: 0 0 10px 0;
  }

  h4, h5, h6 {
    font: 18px/22px Verdana, Arial, sans-serif;
    margin: 0;
  }

  p {
    margin-top: 0;
  }

  ol {
    padding-left: 22px;
    list-style-type: decimal;
  }

  ul {
    padding-left: 14px;
    list-style-type: disc;
  }

  p + ul,
  p + ol {
    margin-top: -22px;
  }

  .btn {
      position: relative;
      right: auto;
      bottom: auto;
  }
}
